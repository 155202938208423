/* You can add global styles to this file, and also import other style files */


@import "~bootstrap/dist/css/bootstrap.css";
@import '~@fortawesome/fontawesome-free/css/all.css';


/* Defina as cores para personalizar o calendário do ng-bootstrap */
.ngb-dp-month {
  background-color: #ffffff;
  /* Cor de fundo do calendário */
}

.ngb-dp-day {
  background-color: #f2f2f2;
  /* Cor de fundo dos dias do calendário */
  border-radius: 0;
  /* Opcional: remova o arredondamento das bordas */
}

.ngb-dp-day:not(.disabled) {
  color: #333333;
  /* Cor do texto nos dias do calendário */
}

.ngb-dp-weekday {
  color: #00ff59;
  /* Cor de fundo dos dias selecionados */
}

.ngb-dp-day.disabled {
  color: #999999;
  /* Cor do texto nos dias desabilitados */
}

.ngb-dp-day.selected {
  background-color: #00ff59;
  /* Cor de fundo dos dias selecionados */
  color: #ffffff;
  /* Cor do texto nos dias selecionados */
}

/* Fim da Defina as cores para personalizar o calendário do ng-bootstrap */

.ngb-carousel .container-text h1 {

  color: var(--color-white);
  font-size: 24px;
  font-family: var(--font-rubik);
  font-weight: bold;
  margin-bottom: 20px;
}

.ngb-carousel .texto-invisivel  {
  /* color: transparent !important; */
  display: none;
}


.ngb-carousel .borda-iframe {
  width: 100%;
  height: 40%;
  border-radius: 35px ;
}

.ngb-carousel .container-text {
  margin-top: 0;
}



.ngb-carousel .img-pequena {
  height: 20vh;
  width: 100%;
  border-radius: 30px;
  min-width: 100%;
  /* Defina a largura mínima desejada */

}

.ngb-carousel .img-media {
  height: 40vh;
  width: 100%;
  min-width: 100%;
  /* Defina a largura mínima desejada */
  border-radius: 30px;
}

.ngb-carousel .img-grande {
  height: 80vh;
  width: 100%;
  border-radius: 30px;
}

 .ngb-carousel .carousel-control-prev {
  left: 0;
  /* display: none; */

} 
 .ngb-carousel .carousel-control-next {
  position: absolute;
  /* display: none; */
} 
.ngb-carousel {
  overflow: hidden;
}

@media screen and (max-width: 768px){


  .ngb-carousel .carousel-control-prev{
    display: none;
  }

  .ngb-carousel .carousel-control-next{
    display: none;
  }
}

.carousel-inner {
  transition: transform 0.5s ease-in-out;
  display: flex; /* Use display flex para os slides ocuparem a largura total do carousel */
  max-width: 700px;
}

.carousel-item {
  flex: 0 0 100%; /* Garante que cada slide ocupe 100% da largura do carousel */
  transition: opacity 0.5s ease-in-out; /* Adiciona uma transição de opacidade */
}

.carousel-item.next {
  opacity: 0; /* Oculta o próximo slide durante a transição */
}


li.open {
  position: relative;
  list-style: none;
}

li.open::before {
  content: '\25CB'; /* Círculo vazio Unicode */
  color: white; /* Cor do marcador */
  font-size: 1.5em; /* Ajuste o tamanho conforme necessário */
  margin-right: 15px; /* Ajuste a margem para mover o marcador para a direita */
  background-color: transparent; /* Cor de fundo transparente */
}


li.closed {
  position: relative;
  right: 30px;
  list-style: none;
}

li.closed::before {
  content: '\25CF'; /* Círculo vazio Unicode */
  color: white; /* Cor do marcador */
  font-size: 1.5em; /* Ajuste o tamanho conforme necessário */
  margin-right: 15px; /* Ajuste a margem para mover o marcador para a direita */
  background-color: transparent; /* Cor de fundo transparente */
}

.link{
  color: #005E64;
  border-bottom: 1px solid;
}



@font-face {
  font-family: 'Rouse Slab';
  src: url('./assets/fonts/RoseauSlab.ttf') format('opentype');
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Light.ttf') format('opentype');
}

:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --font-rubik: 'Rubik', sans-serif;
  --font-roseau-slab: 'Rouse Slab', sans-serif;

  /* font sizes */
  --font-size-3xs: 10px;
  --font-size-sm: 14px;
  --font-size-lg: 18px;
  --font-size-14xl: 33px;
  --font-size-21xl: 40px;
  --font-size-34xl: 53px;
  --font-size-41xl: 60px;
  --font-size-35xl: 54px;
  --font-size-51xl: 70px;
  --font-size-5xl: 24px;
  --font-size-base: 16px;
  --font-size-smi: 13px;
  --font-size-2xs: 11px;

  /* Colors */
  --color-darkslategray-100: #005e64;
  --color-white: #fff;
  --color-darkturquoise: #00c8c6;
  --color-gainsboro: rgba(217, 217, 217, 0);
  --color-darkkhaki-100: #a9ae60;
  --color-darkkhaki-200: #9da34a;
  --color-darkorange: #ed7202;
  --color-saddlebrown-100: #532014;
  --color-saddlebrown-200: rgba(83, 32, 20, 0.68);
  --color-silver: #bdbdbd;

  /* border radiuses */
  --br-49xl: 68px;
  --br-481xl: 500px;
  --br-21xl: 40px;
  --br-7xs: 6px;
  --br-xl: 20px;
}
